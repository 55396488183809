export const URL_TEST = 'https://test-api.carfixapp.com/v1';
export const URL_DEV = 'https://api.carfix.sergiumihai.dev.ascensys.ro/v1';
// export const URL_DEV = 'https://api.carfix.dragoshomner.dev.ascensys.ro/v1';
export const URL_DEMO = 'https://demo-api.carfixapp.com/v1';
export const URL_LIVE = 'https://api.carfixapp.com/v1';

// Tasks status
export const STATUS_NOT_ACTIVE = 1;
export const STATUS_ACTIVE = 2;
export const STATUS_DELETED = 3;
export const STATUS_ANONYMOUS = 4;

// Documents version
export const TERMS_VERSION = '1/21.12.2020';
export const POLICY_VERSION = '1/21.12.2020';

// Publish
export const URL_ONSTART = URL_DEMO;
export const PUBLISHED_VERSION = true;
