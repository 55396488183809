/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect, useHistory
} from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import Div100vh from 'react-div-100vh';
import { message } from 'antd';
import { AppContext } from './context/app.context';
import {
  LoginPage,
  SettingsPage,
  AddCarPage,
  EditCarPage,
  ViewCarPage,
  TasksPage,
  TaskPage,
  RegisterPage,
  GeneralActionsPage,
  ForgotPasswordPage,
  NewDamagePage,
  PickRolePage,
  DashboardPage,
  GaragePage,
  DeveloperRoom,
  DocumentPage,
  WelcomePage,
  MyDocumentsPage,
  ProtectedPage,
} from './pages';

import {
  StepPickCar, StepInsurance, StepSummary, StepMap2D, StepDetail2D, StepPickClient, StepCustom
} from './pages/new-damage/subpages';
import { Footer, Loader } from './components';

// Settings subpages;
import {
  Profile, LegalPage, NotificationPage, LegalDocumentsPage
} from './pages/settings/subpages';
import { storageGet, storageSave } from './storage/localStorage';
import {
  CascoSubpage,
  CiSubpage,
  CivSubpage,
  ExtinctorSubpage,
  ItpSubpage,
  PermisSubpage,
  RcaSubpage,
  ReviziiSubpage,
  RovinietaSubpage,
  TalonSubpage,
  TrusaSubpage,
} from './pages/my-documents/subpages';
import {
  URL_DEV, URL_LIVE, URL_TEST, URL_DEMO 
} from './constants';
import ResetPasswordPage from './pages/reset-password/reset-password.page';
import { ComponentsContext } from './context/components.context';
import { AskForRegisterModal } from './modules';
import { AskForRegisterContent, AskForRegisterWrapper } from './modules/askForRegister/askForRegister.style';

const getUrlParams = () => {
  const url = window.location.href;

  const params = {};
  `${url}?`
    .split('?')[1]
    .split('&')
    .forEach((pair) => {
      pair = `${pair}=`.split('=').map(decodeURIComponent);
      if (pair[0].length) {
        params[pair[0]] = pair[1];
      }
    });
  return params;
};

function Middlewere() {
  const {
    setUser,
    signout,
    fetchToken,
    setFutureTask,
    futureTask,
    setCustomStepTemplate,
    getMakes,
    SET_API_URL,
    getTasks,
    getRegions,
    fetchNotifications,
    activeEmail,
    appIsBlocked,
  } = useContext(AppContext);
  const { setComponentLevel0 } = useContext(ComponentsContext);

  const history = useHistory();

  const [localParams, setLocalParams] = useState(null);
  const [paramsHandled, setParamasHandled] = useState(false);

  const handleActiveEmail = async (params) => {
    // Open app
    if (params.expo) {
      window.location = `${params.expo}?activeEmail=${params.activeEmail}&env=${params.env || 'live'}`;
    } else if (window.location.origin.includes('test-webapp')) {
      window.location = `carfix://?activeEmail=${params.activeEmail}&env=${params.env || 'test'}`;
    } else {
      window.location = `carfix://?activeEmail=${params.activeEmail}&env=${params.env || 'live'}`;
    }

    // Fetch token info
    const user = await activeEmail(params.activeEmail);

    // If invalid token or 500 stop process
    if (!user) {
      return null;
    }

    message.success('Contul tau a fost activat!');
    // Token data

    // Login user
    setUser(user);
    fetchNotifications(user.jwt);
    storageSave('user', user);
    message.success('Bun venit!');

    getTasks(user.jwt);
    getMakes(user.jwt, user.user.id);
    getRegions();

    history.replace({ pathname: '/' });
  };

  const handleUrlParams = async (params) => {
    setParamasHandled(true);

    if (params.activeEmail) {
      handleActiveEmail(params);
    }

    if (params.token) {
      // Signout user
      signout();

      // Remove params from link
      history.replace({ pathname: '/' });

      // Try to open app with the same params
      if (params.expo) {
        window.location = `${params.expo}?token=${params.token}&env=${params.env || 'live'}`;
      } else if (window.location.origin.includes('test-webapp')) {
        window.location = `carfix://?token=${params.token}&env=${params.env || 'test'}`;
      } else {
        window.location = `carfix://?token=${params.token}&env=${params.env || 'live'}`;
      }

      // Fetch token info
      const res = await fetchToken(params.token);

      // If invalid token or 500 stop process
      if (!res) {
        return null;
      }

      // Token data
      const {
        type, user, template, step_insurance, task, car
      } = res;

      // Login user
      setUser(user);
      fetchNotifications(user.jwt);
      storageSave('user', user);
      message.success('Bun venit!');

      getTasks(user.jwt);
      getMakes(user.jwt, user.user.id);
      getRegions();

      // Handle token type (#add switch here#)
      if (type === 'task_details') {
        return history.replace(`/task?id=${task.task_id}`);
      }

      if (type === 'continue_task_ir') {
        // Aici de adus inspector din token
        const nextFutureTask = {
          ...futureTask,
          task,
          stepInsurance: step_insurance,
          inspector: user.user.id,
          type: 'IR',
          client: user.user,
          car,
        };

        setFutureTask(nextFutureTask);
        storageSave('futureTask', nextFutureTask);
        setCustomStepTemplate(template);
        storageSave('customTemplate', template);

        if (user.user.status === 'anonymous') {
          setComponentLevel0(<AskForRegisterModal />);
        }
        history.push({ pathname: '/step-custom/2' });
      }
    }
  };

  useEffect(() => {
    if (localParams) {
      handleUrlParams(localParams);
    }
  }, [localParams]);

  useEffect(() => {
    const params = getUrlParams();

    if (params && !paramsHandled) {
      if (params.env) {
        if (params.env === 'dev') {
          SET_API_URL(URL_DEV);
        }

        if (params.env === 'test') {
          SET_API_URL(URL_TEST);
        }
        if (params.env === 'demo') {
          SET_API_URL(URL_DEMO);
        }
      }
      setLocalParams(params);
    }
  }, []);

  return <></>;
}
export default function Navigation(props) {
  const {
    user, setUser, appIsBlocked, API_URL
  } = useContext(AppContext);
  const { componentLevel0, setComponentLevel0 } = useContext(ComponentsContext);
  const [userChecked, setUserChecked] = useState(false);

  const params = getUrlParams();

  function wrongBrowser() {
    setComponentLevel0(
      <AskForRegisterWrapper>
        <AskForRegisterContent style={{ textAlign: 'center' }}>
          <h2 style={{ lineHeight: '20px' }}>Browser-ul nu este suportat!</h2>
          <p>Te rugăm să deschizi acest link cu browser-ul predefinit al telefonului.</p>
        </AskForRegisterContent>
      </AskForRegisterWrapper>
    );
  }

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      wrongBrowser();
    }

    if (storageGet('user')) {
      setUser(storageGet('user'));
    }

    setUserChecked(true);
  }, []);

  if (!userChecked) {
    return <Loader />;
  }

  return (
    <Router>
      {componentLevel0 && componentLevel0}

      <ScrollMemory elementID="TasksPageWrapper" />
      <Div100vh style={{ minHeight: '80vh' }}>
        <Switch>
          {/* Allowed even if the app is blocked */}
          <Route path="/politica-de-confidentialitate">
            <LegalPage fileType="gdpr" />
          </Route>

          <Route path="/termeni-si-conditii">
            <LegalPage fileType="terms" />
          </Route>
          <Route path="/politica-de-confidentialitate_en-US">
            <LegalPage fileType="gdpr_en-US" />
          </Route>

          <Route path="/termeni-si-conditii_en-US">
            <LegalPage fileType="terms_en-US" />
          </Route>
          <Route path="/regulament-concurs">
            <LegalPage fileType="contest_rules" />
          </Route>

          {/* App is blocked */}
          {API_URL !== URL_LIVE && API_URL !== URL_TEST && API_URL !== URL_DEMO && appIsBlocked && (
            <Route path="*">
              <ProtectedPage />
            </Route>
          )}

          <Route exact path="/">
            {params.token || params.env || params.activeEmail || params.expo ? <Middlewere /> : <PickRolePage />}
          </Route>

          <Route path="/welcome">
            <WelcomePage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/register">
            <RegisterPage />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordPage />
          </Route>
          <Route path="/reset-password">
            <ResetPasswordPage />
          </Route>

          <Route path="/legal-documents">
            <LegalDocumentsPage />
          </Route>

          {/* Dashboard */}

          <PrivateRoute path="/dashboard">
            <DashboardPage />
          </PrivateRoute>

          <PrivateRoute path="/garage">
            <GaragePage />
          </PrivateRoute>

          {/* My documents */}
          <PrivateRoute path="/my-documents" exact>
            <MyDocumentsPage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/rca">
            <RcaSubpage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/casco">
            <CascoSubpage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/permis">
            <PermisSubpage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/talon">
            <TalonSubpage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/civ">
            <CivSubpage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/itp">
            <ItpSubpage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/rovinieta">
            <RovinietaSubpage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/extinctor">
            <ExtinctorSubpage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/trusa">
            <TrusaSubpage />
          </PrivateRoute>
          <PrivateRoute path="/my-documents/revizii">
            <ReviziiSubpage />
          </PrivateRoute>

          <PrivateRoute path="/my-documents/ci">
            <CiSubpage />
          </PrivateRoute>
          {/* Dashboard - Tasks */}
          <PrivateRoute path="/tasks">
            <TasksPage />
          </PrivateRoute>
          <PrivateRoute path="/document">
            <DocumentPage />
          </PrivateRoute>
          <PrivateRoute path="/developer-room">
            <DeveloperRoom />
          </PrivateRoute>

          <PrivateRoute exact path="/task">
            <TaskPage />
          </PrivateRoute>

          {/* General actions */}
          <PrivateRoute path="/add-car">
            <AddCarPage />
          </PrivateRoute>
          <PrivateRoute path="/view-car">
            <ViewCarPage />
          </PrivateRoute>
          <PrivateRoute path="/edit-car">
            <EditCarPage />
          </PrivateRoute>
          <PrivateRoute path="/general-actions">
            <GeneralActionsPage />
          </PrivateRoute>
          <PrivateRoute path="/new-damage">
            <NewDamagePage />
          </PrivateRoute>

          {/* Steps */}
          <PrivateRoute path="/step-pick-client">
            <StepPickClient />
          </PrivateRoute>

          <PrivateRoute path="/step-pick-car">
            <StepPickCar />
          </PrivateRoute>
          <PrivateRoute path="/step-insurance">
            <StepInsurance />
          </PrivateRoute>
          <PrivateRoute path="/step-map-2d">
            <StepMap2D />
          </PrivateRoute>
          <PrivateRoute path="/step-detail-2d">
            <StepDetail2D />
          </PrivateRoute>
          <PrivateRoute path="/step-summary">
            <StepSummary />
          </PrivateRoute>
          <PrivateRoute path="/step-custom/:id">
            <StepCustom />
          </PrivateRoute>

          {/* Settings */}
          <PrivateRoute path="/settings">
            <SettingsPage />
          </PrivateRoute>

          <PrivateRoute path="/profile">
            <Profile />
          </PrivateRoute>

          <PrivateRoute path="/notification">
            <NotificationPage />
          </PrivateRoute>

          <Route path="*">
            <div>PAGINA 404</div>
          </Route>
        </Switch>
        {user && !appIsBlocked && <Footer />}
      </Div100vh>
    </Router>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const { user } = useContext(AppContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: storageGet('welcomeTutorial') ? '/' : '/',
              state: { from: location },
            }}
          />
        ))}
    />
  );
}
