/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo, useState, createContext, useEffect 
} from 'react';
import axios from 'axios';
import { message } from 'antd';
import { URL_ONSTART } from '../constants/index';
import { storageSave, storageGet } from '../storage/localStorage';
import { useLocalStorage } from '../hooks/useLocalStorage';

// CONTEXT ===================================
const AppContext = createContext();
function AppProvider(props) {
  // API URL
  const [API_URL, SET_API_URL] = useLocalStorage('API_URL', URL_ONSTART);
  // General app

  // Blocked app
  const [appIsBlocked, setAppIsBlocked] = useLocalStorage('appIsBlocked', !window.location.origin.includes('localhost'));
  const [user, setUser] = useState(null);
  const [currentDetailTask, setCurrentDetailTask] = useLocalStorage('current_detail_taks', null);

  const [preventChangeScreen, setPreventChangeScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cars, setCars] = useLocalStorage('cars', null);
  const [loginRole, setLoginRole] = useLocalStorage('login_role', 'client');
  const [currentMap2DObject, setCurrentMap2DObject] = useState(null); // Array of polygon points and image url
  const [autoTemplate, setAutoTemplate] = useState(null);

  const [customStepTemplate, setCustomStepTemplate] = useState(null);
  const [makes, setMakes] = useState(storageGet('makes'));
  const [fuelTypes, setFuelTypes] = useState(storageGet('fuelTypes'));
  const [bodyTypes, setBodyTypes] = useState(storageGet('bodyTypes'));

  const [regions, setRegions] = useLocalStorage('regions', null);
  const [userLocation, setUserLocation] = useState(null);

  // Notifications
  const [notifications, setNotifications] = useState([]);

  // Images
  const [fileUploading, setFileUploading] = useState(false);

  // Garage
  const [selectedCarFromGarage, setSelectedCarFromGarage] = useLocalStorage('selectedCarFromGarage', null);

  const initialFutureTask = {
    type: 'FNOL',
    client: {},
    inspector: {},
    car: {},
    damageForm: {},
    stepDocumentsInjured: {},
    stepDocumentsGuilty: {},
    stepInsurance: {},
    stepPolicy: {},
    stepDeclarant: {},
    stepGuilty: {},
    stepLocation: {},
  };
  // Get tasks
  const [tasks, setTasks] = useState({
    STATE_APPROVED: [],
    STATE_CLOSED: [],
    STATE_COMPLETED: [],
    STATE_NEW: [],
    STATE_IN_PROGRESS: [],
    STATE_REJECTED: [],
  });

  // Future task
  const [futureTask, setFutureTask] = useState(initialFutureTask);

  const handleExpireNotificationClick = async (data, history) => {
    if (data.category === 'expire_personal') {
      const { doc_code } = data;

      doc_code === 'ci' && history.push('my-documents/ci');
      doc_code === 'permis' && history.push('my-documents/permis');
    }

    if (data.category === 'expire_car') {
      const { doc_code, car_id } = data;
      let car;
      let fetchedCars;

      if (!cars) {
        fetchedCars = await getClientCars(user.user.id);
      }

      if (fetchedCars) {
        car = fetchedCars.find((element) => element.id == car_id);
      } else {
        car = cars.find((element) => element.id == car_id);
      }

      setSelectedCarFromGarage(car);

      doc_code === 'rca' && history.push('my-documents/rca');
      doc_code === 'casco' && history.push('my-documents/casco');
      doc_code === 'civ' && history.push('my-documents/civ');
      doc_code === 'talon' && history.push('my-documents/talon');
      doc_code === 'itp' && history.push('itp');
      doc_code === 'rovinieta' && history.push('rovinieta');
      doc_code === 'extinctor' && history.push('my-documents/extinctor');
      doc_code === 'trusa_med' && history.push('my-documents/trusa');
      doc_code === 'revizii' && history.push('revizii');
    }
  };

  const fetchNotifications = (token) => {
    axios({
      method: 'get',
      url: `${API_URL}/notification/all`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token || user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setNotifications(response.data);
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
      });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => { setUserLocation(position.coords); });
  }, []);
  
  const fetchToken = (token) =>
    axios({
      method: 'get',
      url: `${API_URL}/task/token?id=${token}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization:
          'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGkuZWluc3BlY3Rvci5zZXJnaXVtaWhhaS5kZXYuYXNjZW5zeXMucm8iLCJhdWQiOiJodHRwOlwvXC9hcGkuZWluc3BlY3Rvci5zZXJnaXVtaWhhaS5kZXYuYXNjZW5zeXMucm8iLCJpYXQiOjE1ODU3Mjg5MjQsIm5iZiI6MTU4NTcyODkyNCwiZXhwIjoxNjE3MjY0OTI0LCJqdGkiOjN9.qQ5_nGritl6SGsKTQ0oWFLvgwJuF34rGOUNg0Fdq-rA',
      },
    })
      .then(
        (response) =>
          // handle success
          response.data
      )
      .catch((response) => {
        // handle error
        message.error('Invalid token');
      });

  useEffect(() => {
    if (user) {
      storageSave('futureTask', futureTask);
    } else if (storageGet('futureTask')) {
      setFutureTask(storageGet('futureTask'));
    }
  }, []);

  const activeEmail = (token) =>
    axios({
      method: 'post',
      url: `${API_URL}/auth/activation`,
      data: { token },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(
        (response) =>
          // handle success
          response.data
      )
      .catch((response) => {
        // handle error
        message.error('Invalid token');
      });

  useEffect(() => {
    if (user && currentMap2DObject) {
      storageSave('currentMap2DObject', currentMap2DObject);
    } else if (storageGet('currentMap2DObject')) {
      setCurrentMap2DObject(storageGet('currentMap2DObject'));
    }
  }, [currentMap2DObject]);

  useEffect(() => {
    if (!regions) {
      getRegions();
    }
  }, []);

  const getUserDocuments = async (type, car_id = null) => {
    setIsLoading(true);

    let url = `${API_URL}/document/get?userId=${user.user.id}&type=${type}`;

    if (car_id) {
      url += `&car_id=${car_id}`;
    }

    const res = await axios({
      method: 'get',
      url,
      headers: { Authorization: `Bearer ${user.jwt}` },
    })
      .then((res) => {
        setIsLoading(false);
        return { error: false, data: res.data };
      })
      .catch((e) => {
        handleError(e);
        return { error: true, message: e };
      });

    setIsLoading(false);
    return res;
  };

  const handleError = (e) => {
    setIsLoading(false);
    message.error(e.response.data.message);
    // throw e;
    console.error(e);
  };

  const saveUserDocuments = async (data) => {
    setIsLoading(true);

    const bodyFormData = new FormData();
    data.car_id && bodyFormData.append('car_id', data.car_id);
    data.type && bodyFormData.append('type', data.type);
    data.id_user && bodyFormData.append('id_user', data.id_user);
    data.document && bodyFormData.append('document', data.document);
    data.document1 && bodyFormData.append('document1', data.document1);
    data.document2 && bodyFormData.append('document2', data.document2);

    data.serie && bodyFormData.append('serie', data.serie);
    data.numar && bodyFormData.append('numar', data.numar);
    data.start_date && bodyFormData.append('start_date', data.start_date);
    data.end_date && bodyFormData.append('end_date', data.end_date);
    data.asigurator?.name && bodyFormData.append('asigurator[name]', data.asigurator?.name);
    data.asigurator?.id && bodyFormData.append('asigurator[id]', data.asigurator?.id);
    data.alert && bodyFormData.append('alert', data.alert);

    const res = await axios({
      method: 'post',
      url: `${API_URL}/document/save`,
      headers: {
        Authorization: `Bearer ${user.jwt}`,
        'Content-type': 'multipart/form-data',
      },
      data: bodyFormData,
    })
      .then((res) => {
        message.success('Document schimbat');
        setIsLoading(false);
        return { error: false, data: res.data };
      })
      .catch((e) => {
        handleError(e);
        message.error('Error');
        return { error: true, message: e };
      });

    setIsLoading(false);
    return res;
  };

  const getAutoTemplate = async (body_type_id) => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set('body_type_id', body_type_id);

    const resultsPromise = axios({
      method: 'post',
      url: `${API_URL}/inspection/templates-auto`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setAutoTemplate(response.data.message.templates);
        setIsLoading(false);
        return response.data.message.templates;
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);

        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
      });
    const results = await resultsPromise;
    return results;
  };

  const uploadFile = ({ base64, file }) => {
    setFileUploading(true);

    const bodyFormData = new FormData();
    bodyFormData.set('file', file);

    return axios({
      method: 'post',
      url: `${API_URL}/task/tmp-file`,
      data: file ? bodyFormData : { base64 },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        setFileUploading(false);
        return response.data;
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
        setFileUploading(false);
        return false;
      });
  };

  const getCupiTemplate = (jwt, body_type_id) => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set('body_type_id', body_type_id);

    axios({
      method: 'post',
      url: `${API_URL}/inspection/templates-cupi`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setCurrentMap2DObject(response.data.message);

        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
      });
  };

  const getMakes = (jwt, id) => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set('userId', id);

    axios({
      method: 'post',
      url: `${API_URL}/app/fetch-dictionaries`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((response) => {
        // handle success

        const arrayFromObject = Object.keys(response.data.bodyTypes).reduce(
          (acc, curr) => [...acc, { id: curr, name: response.data.bodyTypes[curr] }],
          []
        );
        setBodyTypes(arrayFromObject);
        storageSave('bodyTypes', arrayFromObject);

        setFuelTypes(response.data.fuelTypes);
        storageSave('fuelTypes', response.data.fuelTypes);

        setMakes(response.data.makes);
        storageSave('makes', response.data.makes);

        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
      });
  };

  const updateProfile = (formData) => {
    setIsLoading(true);
    const bodyFormData = new FormData();

    if (formData.email) {
      bodyFormData.set('email', formData.email);
    }

    if (formData.firstName) {
      bodyFormData.set('firstName', formData.firstName);
    }

    if (formData.lastName) {
      bodyFormData.set('lastName', formData.lastName);
    }

    if (formData.phone) {
      bodyFormData.set('phone', formData.phone);
    }

    if (formData.password) {
      bodyFormData.set('password', formData.password);
    }
    if (formData.confirm) {
      bodyFormData.set('repeatPassword', formData.confirm);
    }
    bodyFormData.set('userId', user.user.id);
    if (formData.avatar) {
      bodyFormData.set('avatar', formData.avatar);
    }

    // Adress

    if (formData.county) {
      bodyFormData.set('county', formData.county);
    }

    if (formData.location) {
      bodyFormData.set('location', formData.location);
    }

    if (formData.street) {
      bodyFormData.set('street', formData.street);
    }

    if (formData.bloc) {
      bodyFormData.set('bloc', formData.bloc);
    }

    if (formData.numar) {
      bodyFormData.set('numar', formData.numar);
    }

    if (formData.scara) {
      bodyFormData.set('scara', formData.scara);
    }

    if (formData.ap) {
      bodyFormData.set('ap', formData.ap);
    }

    if (formData.etaj) {
      bodyFormData.set('etaj', formData.etaj);
    }

    bodyFormData.set('userId', user.user.id);

    return axios({
      method: 'post',
      url: `${API_URL}/profile/save`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setUser({ ...response.data });

        storageSave('user', response.data);
        setIsLoading(false);
        message.success('Profil salvat!');

        return response;
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1251:', response);
        }
      });
  };

  const addNewCar = (formData) => {
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.append('client_id', user.user.id);
    bodyFormData.append('body_id', formData.body_id);
    bodyFormData.append('fuel_type', formData.fuel_type);
    bodyFormData.append('make_id', formData.make_id);
    bodyFormData.append('model_id', formData.model_id);
    bodyFormData.append('model_year', formData.model_year);
    bodyFormData.append('registration_number', formData.registration_number);
    bodyFormData.append('transmission', '');
    bodyFormData.append('vin', formData.vin);
    bodyFormData.append('avatar', formData.avatar);

    return axios({
      method: 'post',
      url: `${API_URL}/inspection/add-car`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success

        if (response.data.error) {
          message.error(response.data.message);
          setIsLoading(false);
          return false;
        }
        message.success('Mașina a fost adaugată');
        getClientCars(user.user.id);
        setIsLoading(false);
        return true;
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1253:', response);
        }
        return false;
      });
  };

  const getRegions = () => {
    axios({
      method: 'get',
      url: `${API_URL}/county`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        // handle success
        // Add 6 sectors
        const regionsWithSectors = [
          ...response.data,
          {
            id: 44,
            abbr: 's1',
            name: 'Sectorul 1',
          },
          {
            id: 45,
            abbr: 's2',
            name: 'Sectorul 2',
          },
          {
            id: 46,
            abbr: 's3',
            name: 'Sectorul 3',
          },
          {
            id: 47,
            abbr: 's4',
            name: 'Sectorul 4',
          },
          {
            id: 48,
            abbr: 's5',
            name: 'Sectorul 5',
          },
          {
            id: 49,
            abbr: 's6',
            name: 'Sectorul 6',
          },
        ];
        setRegions(regionsWithSectors);
      })
      .catch((response) => {
        // handle error
        try {
          message.error({
            message: response.response.data.message,
            type: 'error',
          });
        } catch (e) {
          console.log('Repsonse 125a122:', response);
        }
      });
  };

  const updateCar = (formData) => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set('client_id', user.user.id);
    bodyFormData.set('id', formData.id);
    bodyFormData.set('body_id', formData.body_id);
    bodyFormData.set('fuel_type', formData.fuel_type);
    bodyFormData.set('make_id', formData.make_id);
    bodyFormData.set('model_id', formData.model_id);
    bodyFormData.set('model_year', formData.model_year);
    bodyFormData.set('registration_number', formData.registration_number);
    bodyFormData.set('transmission', '');
    bodyFormData.set('vin', formData.vin);

    axios({
      method: 'post',
      url: `${API_URL}/inspection/update-car`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        message.success('Datele mașinii  au fost actualizate!');
        getClientCars(user.user.id);
        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1253:', response);
        }
      });
  };

  const getClientCars = (client_id) => {
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.set('client[id]', client_id);

    axios({
      method: 'post',
      url: `${API_URL}/inspection/client-cars`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setCars(response.data.message.cars);

        storageSave('client_cars', response.data.message.cars);
        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1253:', response);
        }
      });
  };

  const authenticate = (formData) => {
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.set('username', formData.username);
    bodyFormData.set('password', formData.password);
    bodyFormData.set('role', loginRole === 'constatator' ? 'inspector' : loginRole);

    axios({
      method: 'post',
      url: `${API_URL}/auth/login`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        // handle success
        setIsLoading(false);

        setUser(response.data);
        storageSave('user', response.data);
        message.success('Bun venit!');

        getTasks(response.data.jwt);
        fetchNotifications(response.data.jwt);
        getMakes(response.data.jwt, response.data.user.id);
        getRegions();
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1251:', response);
        }
      });
  };

  const handleAnonymousLogin = () => {
    setIsLoading(true);

    axios({
      method: 'post',
      url: `${API_URL}/auth/guest`,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        // handle success
        setIsLoading(false);

        setUser(response.data);
        storageSave('user', response.data);
        message.success('Bun venit!');

        getTasks(response.data.jwt);
        getMakes(response.data.jwt, response.data.user.id);
        getRegions();
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1251:', response);
        }
        setIsLoading(false);
      });
  };

  const forgotPassword = (formData) => {
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.set('reset_string', formData.email_or_phone);

    axios({
      method: 'post',
      url: `${API_URL}/auth/reset-password`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        // handle success
        if (response.data.success) {
          message.success('Email trimis cu succes!');
        } else {
          message.error(response.data.message);
        }
        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1251:', response);
        }
      });
  };

  const deleteAccount = (formData) => {
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.set('password', formData.password);

    return axios({
      method: 'post',
      url: `${API_URL}/user/delete-account`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${user.jwt}` },
    })
      .then((response) => {
        // handle success
        if (response.data.error) {
          message.error(response.data.message);
        } else {
          message.success(response.data.message);
          signout();
        }
        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      });
  };

  const handleRegister = (formData) => {
    setIsLoading(true);

    axios({
      method: 'post',
      url: `${API_URL}/auth/invite`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        // handle success

        if (response.data.error) {
          message.error(response.data.message);
        } else {
          storageSave('user', response.data);
          getMakes(response.data.jwt, response.data.user.id);

          setUser(response.data);
        }
        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        console.log('Repsonse 1251:', response);
        setIsLoading(false);
      });
  };

  const signout = () => {
    setUser(null);
    setCars([]);
    setFutureTask(initialFutureTask);
    setNotifications([]);
    setTasks({
      STATE_APPROVED: [],
      STATE_CLOSED: [],
      STATE_COMPLETED: [],
      STATE_NEW: [],
      STATE_IN_PROGRESS: [],
      STATE_REJECTED: [],
    });

    // Save api url from clear
    localStorage.clear();
  };

  const getTasks = (jwt) => {
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.set('taskState', 'STATE_NEW,STATE_IN_PROGRESS,STATE_REJECTED,STATE_COMPLETED,STATE_APPROVED,STATE_CLOSED');

    axios({
      method: 'post',
      url: `${API_URL}/task/all`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((response) => {
        // handle success
        const responseData = Object.values(response.data);
        responseData.pop();

        // task_status
        const STATE_APPROVED = responseData.filter((task) => task.task_status.code === 'STATE_APPROVED');
        const STATE_CLOSED = responseData.filter((task) => task.task_status.code === 'STATE_CLOSED');
        const STATE_COMPLETED = responseData.filter((task) => task.task_status.code === 'STATE_COMPLETED');
        const STATE_NEW = responseData.filter((task) => task.task_status.code === 'STATE_NEW');
        const STATE_IN_PROGRESS = responseData.filter((task) => task.task_status.code === 'STATE_IN_PROGRESS');
        const STATE_REJECTED = responseData.filter((task) => task.task_status.code === 'STATE_REJECTED');

        setTasks({
          STATE_APPROVED,
          STATE_CLOSED,
          STATE_COMPLETED,
          STATE_NEW,
          STATE_IN_PROGRESS,
          STATE_REJECTED,
        });

        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);
        console.dir(response);
      });
  };

  const store = {
    // General app
    isLoading,
    setIsLoading,
    appIsBlocked,
    setAppIsBlocked,
    preventChangeScreen,
    setPreventChangeScreen,
    // Login role
    loginRole,
    setLoginRole,

    // User
    user,
    setUser,
    tasks,
    getClientCars,
    cars,
    updateProfile,
    getMakes,
    makes,
    handleAnonymousLogin,
    regions,

    // Cars
    addNewCar,
    updateCar,
    fuelTypes,
    bodyTypes,

    // Cupi
    getCupiTemplate,
    currentMap2DObject,
    setCurrentMap2DObject,
    getAutoTemplate,
    autoTemplate,
    setAutoTemplate,
    // Future task
    futureTask,
    setFutureTask,

    // Auth
    forgotPassword,
    authenticate,
    handleRegister,
    signout,
    deleteAccount,
    // Tasks
    getTasks,
    initialFutureTask,
    currentDetailTask,
    setCurrentDetailTask,
    customStepTemplate,
    setCustomStepTemplate,
    getRegions,
    // Notifications
    fetchNotifications,
    notifications,
    handleExpireNotificationClick,

    // Photos
    fileUploading,
    setFileUploading,
    uploadFile,
    saveUserDocuments,
    getUserDocuments,
    // Api url
    API_URL,
    SET_API_URL,

    // Continue from email
    fetchToken,
    activeEmail,
    // Garage
    selectedCarFromGarage,
    setSelectedCarFromGarage,

    userLocation,
    setUserLocation
  };

  const storeForProvider = useMemo(() => store, [store]);
  return <AppContext.Provider value={storeForProvider}>{props.children}</AppContext.Provider>;
}

export { AppContext };
export default AppProvider;
