/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  CamaraWrapper,
  ConfirmationPreviewWrapper,
  ConfirmationActionWrapper,
  CamaraHeader,
  OptionWrapper,
  TutorialWrapper,
  CommentTag,
  CamaraHeaderInfo,
  ImageWrapper,
  IconWrapper,
  CameraTitleWrapper,
  SelectorWrapper,
  SelectorOption,
  CameraSubTitleWrapper,
} from "./comments-camara.styled";

import { CloseOutlined } from "@ant-design/icons";
import { message } from "antd";
import { AppContext } from "../../context/app.context";
import axios from "axios";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import { FancyHeader, Button } from "..";

export default function CommentsCamara({
  setPhotoURI,
  camaraVisible,
  title,
  editMode,
  carPart,
  setCamaraVisible,
  setPreviewPhotoVisible,
  setCurrentPart,
  setImagePreviewFullScreenMode,
  isAnsamblu,
  setIsAnsamblu,
  setRetakePicture,
  retakePicture,
  onFinish,
  disableUpload,
}) {
  const [dataUri, setDataUri] = useState("");
  const { user, currentMap2DObject, setCurrentMap2DObject, API_URL, uploadFile } = useContext(AppContext);

  const [confirmationPreview, setConfirmationPreview] = useState(true);
  const [commentsList, setCommentsList] = useState([]);
  const [currentComment, setCurrentComment] = useState(null);
  const [tutorialVisible, setTutorialVisible] = useState(true);

  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.set("cupi_part_id", carPart.cupi_id);

    axios({
      method: "post",
      url: `${API_URL}/inspection/cupi-comments`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then(function (response) {
        //handle success
        setCommentsList(response.data.message.comments);
      })
      .catch(function (response) {
        //handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log("Repsonse 1251:", response);
        }
      });
  }, []);

  const setCommentInTemplate = () => {
    const futureMap2DObject = currentMap2DObject;
    let partIndex;
    futureMap2DObject.templates.map((part, index) => {
      if (part.cupi_id == carPart.cupi_id) {
        partIndex = index;
      }
    });

    let object = {};
    object.value = {};
    object.value.base64 = dataUri;
    object.comments = {
      x: commentPosX,
      y: commentPosY,
      cupi_comment_id: currentComment.cupi_comment_id,
      description: currentComment.description,
    };

    if (!futureMap2DObject.templates[partIndex].photos.length) {
      futureMap2DObject.templates[partIndex].photos = [];
      object.label = "Ansamblu";
      setIsAnsamblu(false);
    } else if (futureMap2DObject.templates[partIndex].photos.length === 1) {
      object.label = "Detaliu";
      setIsAnsamblu(true);
      setCurrentComment(futureMap2DObject.templates[partIndex].photos[0].comments);
    } else {
      if (isAnsamblu) {
        object.label = "Ansamblu";
      } else {
        object.label = "Detaliu";
        setIsAnsamblu(true);
      }
    }
    var findElem = futureMap2DObject.templates[partIndex].photos.filter((photo) => photo.label === object.label);
    if (findElem.length) {
      let pIndex;
      futureMap2DObject.templates[partIndex].photos.map((part, index) => {
        if (part.label === findElem[0].label) {
          pIndex = index;
        }
      });
      futureMap2DObject.templates[partIndex].photos[pIndex] = object;
    } else {
      futureMap2DObject.templates[partIndex].photos.push(object);
    }

    setCurrentMap2DObject(futureMap2DObject);
  };

  const getLabelTemplate = () => {
    if (editMode) {
      return "Schimba poza";
    } else if (isAnsamblu && !retakePicture) {
      return "Adauga poza de detaliu";
    } else if (retakePicture) {
      return "Confirm";
    }
    return "Confirm";
  };

  const handleAcceptPhoto = () => {
    if (isAnsamblu && !retakePicture) {
      setCommentInTemplate();
      setTutorialVisible(true);
      setCommentPosX(0);
      setDataUri("");
      setCommentPosY(0);
    } else {
      setCommentInTemplate();
      setImagePreviewFullScreenMode(false);
      setCamaraVisible(false);
      setPhotoURI(dataUri);
      setRetakePicture(false);
    }
  };

  const handleAcceptPhotoOnEdit = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        carPartClone.coords = position.coords;
      });
    }

    const carPartClone = JSON.parse(JSON.stringify(carPart));

    carPartClone.comment = {
      coords: {
        left: commentPosX,
        top: commentPosY,
      },
      key: currentComment.cupi_comment_id,
      label: currentComment.description,
    };

    setPhotoURI(dataUri, carPartClone);
    setImagePreviewFullScreenMode(false);
    setCamaraVisible(false);
    onFinish &&
      onFinish({
        photo: dataUri,
        comment: {
          key: currentComment.cupi_comment_id,
          label: currentComment.description,
          coords: {
            top: commentPosY,
            left: commentPosX,
          },
        },
      });
  };

  function onTakePhoto(dataUri) {
    setDataUri(dataUri);
  }

  const renderPhotoCommentSelector = () => {
    return (
      <ConfirmationPreviewWrapper className="ConfirmationPreviewWrapper">
        <SelectorWrapper className="SelectorWrapper fadeInUp animated faster">
          {commentsList.length > 0 ? <h2 className="TitleWrapper">Alege tipul daunei</h2> : <h2>Incarcam lista</h2>}
          <SelectorOption>
            {commentsList.map((comment) => (
              <OptionWrapper
                onClick={() => {
                  setConfirmationPreview(false);
                  setCurrentComment(comment);
                }}
              >
                {comment.description}
              </OptionWrapper>
            ))}
          </SelectorOption>
        </SelectorWrapper>
      </ConfirmationPreviewWrapper>
    );
  };

  const [commentPosX, setCommentPosX] = useState(0);
  const [commentPosY, setCommentPosY] = useState(0);

  const renderTutorial = () => {
    return (
      <TutorialWrapper className="TutorialWrapper">
        <img src="https://cdn3.iconfinder.com/data/icons/hand-gesture-10/64/Tap_hand_gesture_finger_click-512.png" alt="touch icon" />

        <FancyHeader
          title={isAnsamblu ? "Poza de ansamblu" : "Poza de detaliu"}
          subtitle={title && `Indică locul unde ${title?.toLowerCase()} este ${currentComment.description}`}
        />

        <Button
          className="primary"
          onClick={() => {
            setTutorialVisible(false);
          }}
          title="Am înteles"
        />
      </TutorialWrapper>
    );
  };
  const renderCommentPosition = () => {
    return (
      <ConfirmationPreviewWrapper className="ConfirmationPreviewWrapper">
        {tutorialVisible && renderTutorial()}
        <ImageWrapper className="ImageWrapper" id="image">
          <img
            alt="tag"
            onClick={(e) => {
              let percentX = (e.nativeEvent.offsetX * 100) / e.currentTarget.clientWidth;
              let percentY = (e.nativeEvent.offsetY * 100) / e.currentTarget.clientHeight;

              setCommentPosX(percentX);
              setCommentPosY(percentY);
            }}
            src={dataUri}
          />
          {commentPosY && commentPosY ? (
            <CommentTag
              style={{
                top: `${commentPosY}%`,
                left: `${commentPosX}%`,
              }}
            >
              {currentComment.description}
            </CommentTag>
          ) : null}
        </ImageWrapper>

        {commentPosY && commentPosY ? (
          <ConfirmationActionWrapper className="ConfirmationActionWrapper">
            <Button
              className="primary"
              onClick={() => (editMode ? handleAcceptPhotoOnEdit() : handleAcceptPhoto())}
              title={getLabelTemplate()}
            />
          </ConfirmationActionWrapper>
        ) : null}
      </ConfirmationPreviewWrapper>
    );
  };

  const renderCamara = () => {
    return (
      <Camera
        isImageMirror={false}
        isSilentMode
        isFullscreen={true}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        onTakePhoto={async (dataUri) => {
          onTakePhoto(dataUri);

          if (disableUpload) {
            return;
          }
          const result = await uploadFile({ base64: dataUri });

          if (result) {
            onTakePhoto(result.url);
          }
        }}
      />
    );
  };

  const handleCloseCamara = () => {
    setImagePreviewFullScreenMode(false);
    setCamaraVisible(false);
  };

  const renderConditional = () => {
    if (confirmationPreview) {
      return renderPhotoCommentSelector();
    }

    if (currentComment && dataUri) {
      return renderCommentPosition();
    }

    return renderCamara();
  };
  return (
    <CamaraWrapper className="CamaraWrapper">
      <CamaraHeader className="CamaraHeader">
        {isAnsamblu && (
          <IconWrapper className="IconWrapper" onClick={handleCloseCamara}>
            <CloseOutlined />
          </IconWrapper>
        )}

        <CamaraHeaderInfo className="CamaraHeaderInfo">
          {title && <CameraTitleWrapper className="camera-title">{title}</CameraTitleWrapper>}
          {title && <CameraSubTitleWrapper>{isAnsamblu ? "Poza de ansamblu" : "Poza de detaliu"}</CameraSubTitleWrapper>}
        </CamaraHeaderInfo>
      </CamaraHeader>
      {renderConditional()}
    </CamaraWrapper>
  );
}
